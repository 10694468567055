var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-modal',{ref:"edit-modal",attrs:{"no-close-on-backdrop":"","cancel-variant":"outline-secondary","centered":"","size":"lg","hide-footer":"","title":_vm.selectedItem.id > 0 ? _vm.$t('editUnit') : _vm.$t('addUnit')},on:{"hidden":function () {
        _vm.selectedItem = {};
      }}},[_c('g-form',{on:{"submit":_vm.save}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('g-field',{attrs:{"id":"code","type":"number","label-text":"code","value":_vm.selectedItem.code,"name":"code","disabled":""},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "code", $event)}}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('g-field',{attrs:{"id":"arabicName","label-text":"arabicName","value":_vm.selectedItem.arabicName,"rules":("" + (_vm.selectedItem.arabicName ? '' : 'required')),"name":"arabicName"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "arabicName", $event)}}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('g-field',{ref:"englishName",attrs:{"id":"englishName","label-text":"englishName","value":_vm.selectedItem.englishName,"name":"englishName"},on:{"update:value":function($event){return _vm.$set(_vm.selectedItem, "englishName", $event)}}})],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.$route.meta.permission),expression:"$route.meta.permission"}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","data-action-type":"save"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])],1)],1)],1)],1),_c('g-table',{ref:"units-table",attrs:{"items":_vm.items,"columns":_vm.tableColumns,"noAction":true,"perPage":"25","totalRows":_vm.totalRows,"createButton":{ visiable: true },"searchInput":{ visiable: true }},on:{"on-create":function (v) {
        _vm.openModal({
          englishName: ''
        });
      }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('edit')),expression:"$t('edit')",modifiers:{"hover":true,"top":true}},{name:"permission",rawName:"v-permission",value:( 'editUnits'),expression:" 'editUnits'"}],staticClass:"btn-icon",attrs:{"data-action-type":"edit","variant":"flat-secondary","size":"sm"},on:{"click":function($event){return _vm.openModal(item)}}},[_c('feather-icon',{attrs:{"id":("invoice-row-" + (item.id) + "-preview-icon"),"icon":"EditIcon"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('delete')),expression:"$t('delete')",modifiers:{"hover":true,"top":true}},{name:"permission",rawName:"v-permission",value:( 'deleteUnits' ),expression:" 'deleteUnits' "}],staticClass:"btn-icon",attrs:{"data-action-type":"delete","variant":"flat-danger","size":"sm"},on:{"click":function($event){return _vm.remove(item)}}},[_c('feather-icon',{staticClass:"danger",attrs:{"id":("invoice-row-" + (item.id) + "-delete-icon"),"icon":"TrashIcon","stroke":"red"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }